import styled from "@emotion/styled";
import { alpha, Button, Dialog, IconButton, Slide } from "@mui/material";
import { forwardRef } from "react";

export const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export const DialogWrapper = styled(Dialog)(() => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 64px)
    }
`);

export const IconButtonWrapper = styled(IconButton)(``);

export const ButtonWrapper = styled(Button)(({ theme }) => `
    font-weight: normal;
`);