import {
  Box,
  Divider,
  Drawer,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRecoilState } from "recoil";
import Logo from "src/components/LogoSign";
import { isSidebarOpenState } from "src/store";
import SidebarMenu from "./SidebarMenu";

export default function Sidebar() {
  const theme = useTheme();
  const isMobilMode = useMediaQuery(theme.breakpoints.down("lg"));
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenState);

  return (
    <Drawer
      anchor={theme.direction === "rtl" ? "right" : "left"}
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
      variant={isMobilMode ? "temporary" : "permanent"}
      elevation={9}
      sx={{
        width: theme.sidebar.width,
      }}
      PaperProps={{
        sx: {
          borderWidth: "0 1px 0 0",
          background: theme.sidebar.background,
        },
      }}
    >
      <Box
        width={theme.sidebar.width}
        minWidth={theme.sidebar.width}
        color={theme.colors.alpha.trueWhite[70]}
        zIndex={7}
        height={"100%"}
        paddingBottom={"68px"}
        sx={{
          background: theme.sidebar.background,
        }}
      >
        <Stack justifyContent="center" alignItems="center" mt={3} mx={2} p={7}>
          <Logo variant="icon" width={59} height={59} />
        </Stack>
        <Divider
          sx={{
            mt: theme.spacing(3),
            mx: theme.spacing(2),
            background: theme.colors.alpha.trueWhite[10],
          }}
        />
        <SidebarMenu />
      </Box>
    </Drawer>
  );
}
