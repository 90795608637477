import { atom } from "recoil";

export const hoveringOverLegendState = atom({
  key: 'hoveringOverLegend',
  default: null,
});

export const isSidebarOpenState = atom({
  key: 'isSidebarOpen',
  default: false,
});

export const userStore = atom({
  key: 'user',
  default: {}
})