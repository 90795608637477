import { Box, Stack, useTheme } from "@mui/material";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useUser } from "src/hooks/useUser";

export function BaseLayout({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        height: '100%'
      }}
    >
      {children}
    </Box>
  )
};

export function SidebarLayout({ children }) {
  const user = useUser();
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      sx={{
        ".MuiPageTitle-wrapper": {
          background:
            theme.palette.mode === "dark"
              ? theme.colors.alpha.trueWhite[5]
              : theme.colors.alpha.white[50],
        },
      }}
    >
      <Sidebar />
      <Stack
        paddingLeft={theme.sidebar.width}
        sx={{ [theme.breakpoints.down("lg")]: { padding: 0 } }}
        direction="column"
        height="100vh"
        overflow="auto"
      >
        <Header />
        {children}
      </Stack>
    </Stack>
  );
}
