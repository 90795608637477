import { ExpandMoreTwoTone, LockOpenTwoTone } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useRouter } from "next/router";
import { useRef, useState } from "react";
import Logo from "src/components/LogoSign";
import { ROLES } from "src/constants";
import { useTranslation } from "next-i18next";
import { useRecoilValue } from "recoil";
import { userStore } from "src/store";

export default function HeaderUserbox() {
  const { t } = useTranslation("common");
  const user = useRecoilValue(userStore);
  const router = useRouter();
  const theme = useTheme();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleLogout = async () => {
    localStorage.removeItem("dts-should-redirect");
    localStorage.removeItem("viewAsCompany");
    localStorage.removeItem("defaultEdgeId");
    const res = await axios.get("/api/auth/logout");
    if (res.status === 200) {
      router.push("/auth/login");
    }
  };

  const UserBoxContent = ({ hideContentsOnMobile = true }) => (
    <Stack direction="row" spacing={1}>
      <Logo variant="icon" width={25} />
      <Box
        sx={
          hideContentsOnMobile && {
            [theme.breakpoints.down("md")]: { display: "none" },
          }
        }
        textAlign="left"
        pl={theme.spacing(1)}
      >
        <Typography
          overflow="clip"
          noWrap
          maxWidth={160}
          textOverflow="ellipsis"
          fontWeight={700}
          variant="body1"
          display="block"
        >
          {user?.role === ROLES.sysAdmin ? "DTS" : user?.Company?.name || "DTS"}
        </Typography>
        <Typography variant="body2">{!!user ? user.name : "-"}</Typography>
      </Box>
    </Stack>
  );

  return (
    <>
      <Button
        sx={{ pl: theme.spacing(1), pr: theme.spacing(1) }}
        ref={ref}
        color="secondary"
        onClick={() => setOpen(true)}
      >
        <UserBoxContent />
        <ExpandMoreTwoTone sx={{ ml: 1 }} />
      </Button>
      <Popover
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          display="flex"
          height="100%"
          minWidth={210}
          padding={theme.spacing(2)}
          sx={{
            background: theme.colors.alpha.black[5],
          }}
        >
          <UserBoxContent hideContentsOnMobile={false} />
        </Box>
        <Divider />
        <Box margin={1}>
          {user && (
            <Button
              startIcon={<LockOpenTwoTone />}
              fullWidth
              onClick={handleLogout}
            >
              {t("logout")}
            </Button>
          )}
        </Box>
      </Popover>
    </>
  );
}
