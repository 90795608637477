import Image from 'next/image';
import { Fragment } from 'react';
import CountingHeroLogo from './CountingHeroIcon';

export const VARIANTS = {
  IMAGE: 'image',
  ICON: 'icon'
}

export default function Logo({ width=200, height=200, variant = VARIANTS.IMAGE }) {
  return (
    <Fragment>
      {variant === VARIANTS.ICON && (
        <CountingHeroLogo width={width} />
      )}
      {variant === VARIANTS.IMAGE && (
        <Image src="/logo.png" width={width} height={height} alt="" />
      )}
    </Fragment>
  );
}