import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import {
  alpha,
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MoreSettings from "src/components/overlayButtons/MoreSettings";
import { ROLES_LEVEL } from "src/constants";
import HeaderUserbox from "./Userbox";
import { useRecoilState, useRecoilValue } from "recoil";
import { isSidebarOpenState, userStore } from "src/store";

export default function Header() {
  const { breakpoints, header, spacing } = useTheme();
  const user = useRecoilValue(userStore)
  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenState);

  return (
    <Box
      as={Paper}
      id="HeaderWrapper"
      display="flex"
      borderRadius={0}
      height={header.height}
      minHeight={header.height}
      width="100%"
      color={header.textColor}
      padding={spacing(0, 2)}
      justifyContent="flex-end"
      sx={{
        display: "flex",
        position: 'sticky',
        top: 0,
        zIndex: 10,
        [breakpoints.down("lg")]: {
          width: 'auto',
        },
        backgroundColor: alpha(header.background, 0.95),
        backdropFilter: 'blur(3px)'
      }}
    >
      <Stack spacing={2} direction="row" alignItems="center">
        <MoreSettings
          isSysAdmin={ROLES_LEVEL[user?.role] >= ROLES_LEVEL.sysAdmin}
        />
        <HeaderUserbox />
        <Tooltip arrow title="Toggle Menu">
          {isMobile &&
            <IconButton
              color="primary"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              {isSidebarOpen &&  <CloseTwoToneIcon fontSize="medium" />}
              {!isSidebarOpen && <MenuTwoToneIcon fontSize="medium" />}
            </IconButton>
          }
        </Tooltip>
      </Stack>
    </Box>
  );
}
